import React from "react";

import SocialLinks from "./SocialLinks";
import MyIntro from "./MyIntro";

function Portfolio() {
    return (
        <>
            <div className="col-sm-6" id="content-win-1-l">
                < SocialLinks />
            </div>
            <div className="col-sm-6" id="content-win-1-r">
                < MyIntro />
            </div>
        </>
    );
}

export default Portfolio;