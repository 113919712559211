import React from "react";

function TechLogos(props) {
    const logo_image_path = process.env.PUBLIC_URL + '/static/images/techlogos/' + props.logoName + '.png';

    return (
        <img id="tech-logo" src={logo_image_path} alt={props.logoName} />
    );
}

function MyIntro() {
    const headshot_path = process.env.PUBLIC_URL + '/static/images/illack_headshot.png';
    const image_names = ['python_logo','java_logo', 'react_logo',
                        'mysql_logo', 'aws_logo', 'gitlab_logo'];

    return (
        <div className="intro-window">
            <div className="info-container">
                <img className="head-shot" src={headshot_path} alt='illack-chiti' />
                <p className="about-me">
                    ILLACK CHITI<br></br>
                    BSc SOFTWARE ENGINEER, BEng CHEMICAL ENGINEER<br></br>
                    <strong>2 years Software/Data Engineering & Cloud Computing</strong>
                </p>
                <div className="tech-image-icons">
                    {image_names.map((image_name) => <TechLogos logoName={image_name} key={image_name}/>)}
                </div>
            </div>
        </div>
    );
}

export default MyIntro;