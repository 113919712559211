import React, { useState } from "react";
import "../styles/OverlayStyle.css"

const SaasContainer = () => {
    const saas_app_data = {
       ARGOT: ["ARGOT",
            "Makes organic conversation more available to language learners",
            "https://www.argot.cloud"
        ],
       DAH: ["DEGREE APPRENTICE HUB",
        "Supports those seeking career advice",
        "https://www.degreeapprentice.info"
       ]
    };

    return (
        <>
            {Object.entries(saas_app_data).map(([key, value], index) => {
                const saas_logo_path = process.env.PUBLIC_URL + '/static/images/saaslogos/' + key + '.png';
                const [appTitle, appDescription, appLink] = value;
                return(
                    <div className="col-sm-6" id="MySaas-app">
                        <img id="saas-logo" src={saas_logo_path} alt={saas_logo_path}/><br></br>
                        <a id="app-title" href={appLink}>{appTitle}</a>
                        <p id="app-description">{appDescription}</p>
                    </div>
                );
            })}
        </>
    )
}

const SocialLinks = () => {
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const openOverlay = (e) => {
        e.preventDefault();
        setIsOverlayOpen(!isOverlayOpen);
    };

    return (
        <div className="link-window">
            <div className="link-container">
                <a href="https://www.linkedin.com/in/illack-chiti/">LinkedIn</a>
            </div>
            <div className="link-container">
                <a href="https://gitlab.com/illackchiti">GitLab</a>
            </div>
            <div className="link-container">
                <a href="/" onClick={openOverlay} >
                    {!isOverlayOpen ? "MySaas" : "Back"}
                </a>
                {/* add new page which links to more saas I build */}
            </div>
            <div className="link-container">
                <a href="https://leetcode.com/u/illack/">LeetCode</a>
            </div>
            <div className="link-container">
                <a href="https://www.youtube.com/channel/UCfcnRgIuCi5mtYJCCPnbrkQ">YouTube</a>
            </div>
            <div className={`overlay ${isOverlayOpen ? 'slide-in' : 'slide-out'}`}>
                <p className="MySaas-title">MySaas</p>
                <div className="container-fluid" id="my-saas-apps-container">
                    <div className="row align-items-center">
                        <SaasContainer />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SocialLinks;